import moment from "moment";
import net, {ErrorHandlerType, RequestInterceptor, setAuthenticationInfo} from "@ampeco/net";
import settings from "./settings";
import WebsocketMonitor from "./services/WebsocketMonitor";
import {GlobalStoreProxy} from "@ampeco/charge-globalstore";
import GlobalStore from "./stores/GlobalStore";
import Navigation from "@ampeco/navigation";
import * as NavigationService from "./services/NavigationService";
import Alert from "@ampeco/alert";
import AlertAdapter from "./components/Alert/AlertAdapter";
import {bugsnag} from '@ampeco/logging';
import {OAuthAPI} from "../frontend_modules/auth";
import {ActiveTermStore, TermsStore} from "@ampeco/terms";
import {__, LocaleStore} from "../frontend_modules/i18n";
import {MapNavigateService} from '@ampeco/charge-services';
import {Field} from '@ampeco/forms';
import {PaymentMethodDropdown, PaymentMethodDropdownAdapter} from '@ampeco/payment-methods';
import {DatePickerAdapter, DatetimePickerAdapter, TimePickerAdapter} from '@ampeco/forms-datetime-picker';
import Select, {SelectAdapter} from '@ampeco/select';
import {getDeviceId} from './services/DeviceIdService';

export default function init(): void {
    // ADD NEW LANGUAGE MARKER

    LocaleStore.init({
        translations: {
            en: require('../frontend_modules/charge-lang/en.json'),
            bg: require('../frontend_modules/charge-lang/bg.json'),
            mk: require('../frontend_modules/charge-lang/mk.json'),
            el: require('../frontend_modules/charge-lang/el.json'),
            he: require('../frontend_modules/charge-lang/he.json'),
            'zh-CN': require('../frontend_modules/charge-lang/zh-CN.json'),
            'zh-HK': require('../frontend_modules/charge-lang/zh-HK.json'),
            'zh-TW': require('../frontend_modules/charge-lang/zh-TW.json'),
            zh: require('../frontend_modules/charge-lang/zh-TW.json'),
            pt: require('../frontend_modules/charge-lang/pt.json'),
            sl: require('../frontend_modules/charge-lang/sl.json'),
            hr: require('../frontend_modules/charge-lang/hr.json'),
            it: require('../frontend_modules/charge-lang/it.json'),
            de: require('../frontend_modules/charge-lang/de.json'),
            hu: require('../frontend_modules/charge-lang/hu.json'),
            fi: require('../frontend_modules/charge-lang/fi.json'),
            is: require('../frontend_modules/charge-lang/is.json'),
            'sv-SE': require('../frontend_modules/charge-lang/sv-SE.json',),
            'da-DK': require('../frontend_modules/charge-lang/da-DK.json'),
            'tr-TR': require('../frontend_modules/charge-lang/tr-TR.json'),
            'nb-NO': require('../frontend_modules/charge-lang/nb-NO.json'),
            fr: require('../frontend_modules/charge-lang/fr.json'),
            'es-ES': require('../frontend_modules/charge-lang/es-ES.json'),
            'he-x-evedge': require('../frontend_modules/charge-lang/he-x-evedge.json'),
        },
        i18n: {
            available: settings.locale.enabled,
            default: settings.locale.default,
        },
        onSetLocale: (locale) => {
            switch (locale) {
                case 'bg':
                case 'mk':
                case 'el':
                case 'pt':
                case 'sl':
                case 'hr':
                case 'it':
                case 'de':
                case 'hu':
                case 'fi':
                case 'is':
                case 'fr':
                    require(`moment/locale/${locale}.js`);
                    break;
                case 'zh-TW':
                    require('moment/locale/zh-tw.js');
                    moment.locale('zh-tw');
                    break;
                case 'zh-CN':
                    require('moment/locale/zh-cn.js');
                    moment.locale('zh-cn');
                    break;
                case 'zh-HK':
                    require('moment/locale/zh-hk.js');
                    moment.locale('zh-hk');
                    break;
                case 'tr-TR':
                    require('moment/locale/tr.js');
                    moment.locale('tr');
                    break;
                case 'sv-SE':
                    require('moment/locale/sv.js');
                    moment.locale('sv');
                    break;
                case 'da-DK':
                    require('moment/locale/da.js');
                    moment.locale('da');
                    break;
                case 'nb-NO': // Norwegian Bokmål
                    require('moment/locale/nb.js');
                    moment.locale('nb');
                    break;
                case 'es-ES':
                case 'he-x-evedge':
                    require(`moment/locale/${locale.split('-')[0]}.js`);
                    moment.locale(locale.split('-')[0]);
                    break;
                default:
                    moment.locale(locale);
                    break;
            }
        },
    });

    const errorHandler: ErrorHandlerType = (error, fatal, toaster, retry, errorObject) => {
        if (errorObject?.response?.status === 401) {
            return setAuthenticationInfo(null);
        }
        if (errorObject?.response?.status && [404, 405, 422].includes(errorObject.response.status)) {
            return;
        }

        if(
            error.toLowerCase().startsWith('network error')
            || errorObject?.message?.toLowerCase().startsWith('network error')
            || errorObject?.message?.toLowerCase().startsWith('request aborted')
            || errorObject?.code?.toLowerCase() === 'econnaborted') {
            return;
        }

        if (errorObject?.config?.method === 'options') {
            return;
        }

        if (fatal) {
            bugsnag.notify(new Error(error), null, errorObject?.toJSON ? errorObject.toJSON() : null);
        } else {
            bugsnag.leaveBreadcrumb('Encountered non fatal error', {error, errorObject})
        }
    };

    net.init(() => {
        // Intercept requests to oauth/token in order to perform server to server communication.
        const interceptor: RequestInterceptor = (config) => {
            if (config.url !== undefined && (config.url.includes(settings.api.oauth_token_url) || config.url.endsWith('/app/profile/register'))) {
                config.baseURL = settings.serverUrl;
            }

            return config;
        };

        const additional = {
            requestInterceptors: [interceptor]
        }

        return {...settings.api, ...additional};
    }, errorHandler);

    net.setDeviceId(getDeviceId());
    net.setPlatform();

    OAuthAPI.init(() => settings.api);

    bugsnag.init(() => {
        return {
            apiKey: settings.bugsnag.apiKey,
            appVersion: settings.appVersion,
            releaseStage: settings.bugsnag.releaseStage,
        }
    });

    WebsocketMonitor.init(() => settings.broadcast);

    GlobalStoreProxy.init(GlobalStore);
    Navigation.sharedInstance().init(NavigationService);

    Alert.sharedInstance().init(AlertAdapter);

    ActiveTermStore.init({
        onTermSet: (term) => {
            const title = __('terms-change.updated').replace('{title}', term.title);
            GlobalStore.sharedInstance().openMenu();
            Navigation.sharedInstance().navigate('/term-consent', {title});
        },
        onTermLink: (term) => {
            const lang = LocaleStore.sharedInstance().language;

            if (term.urls[lang]) {
                Navigation.sharedInstance().navigate('Terms', {
                    url: term.urls[lang],
                    title: term.title
                });
            }
        },
        onConsent: async () => {
            if (!GlobalStoreProxy.sharedInstance().isAdHocUser) {
                Navigation.sharedInstance().navigate('Home');
            }
            return await TermsStore.sharedInstance().load();
        },
        onCancel: async () => {
            await GlobalStore.sharedInstance().unsetProfile();
            Navigation.sharedInstance().navigate('Home');
            GlobalStore.sharedInstance().closeMenu();
        },
    });

    MapNavigateService.init((location) => {
        const [latitude, longitude] = location.location.split(',').map(parseFloat);
        if (latitude && longitude) {
            const url = `https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${latitude},${longitude}`;
            window.open(url, '_blank');
        }
    })

    Field.additionalAdapters = (type: any) => {
        switch (type) {
            case Select:
                return SelectAdapter;
            case PaymentMethodDropdown:
                return PaymentMethodDropdownAdapter;
            case 'Date':
                return DatePickerAdapter;
            case DatetimePickerAdapter:
                return TimePickerAdapter;
            default:
                return type;
        }
    };
}
