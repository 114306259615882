import axios, {AxiosError, AxiosInstance, AxiosRequestConfig as BaseAxiosRequestConfig, AxiosResponse} from 'axios';
import moment from 'moment';
import {AuthenticationInfo, getAuthenticationInfo, setAuthenticationInfo} from './oauth_storage';
import {bugsnag} from '@ampeco/logging';

let net: AxiosInstance;

export type RequestInterceptor = (config: BaseAxiosRequestConfig) => BaseAxiosRequestConfig;
export type AxiosRequestConfig = BaseAxiosRequestConfig;

export interface NetSettings {
    endpoint: string;
    client_id: string;
    client_secret: string;
    oauth_token_url: string;
    requestInterceptors?: RequestInterceptor[];
    pass_client_id_and_secret_from_register_call: boolean;
}

export type ErrorHandlerType = (errorCode: string, fatalError: boolean, toasterError: boolean, retry?: () => void, error?: Error | AxiosError) => void;

// Shows error and retry
export let errorHandler: ErrorHandlerType = () => {
    console.log('Empty error handler');
};

export let settings: () => NetSettings;

const getSettings = () => {
    return settings();
};

function init(apiSettings: () => NetSettings, errorHandlerCallback: ErrorHandlerType) {
    settings = apiSettings;
    errorHandler = errorHandlerCallback;

    net = axios.create({
        baseURL: settings().endpoint,
        timeout: 60000,
        validateStatus: (status) => {
            return (status >= 200 && status < 300) || status === 401;
        },
    });

    net.interceptors.request.use(async (config) => {

        if (config.url === settings().oauth_token_url) {
            return config; // ignore the token url
        }

        let token = await getAuthenticationInfo();

        if (token === null) {
            return config;
        }
        if (hasExpired(token) && token.refresh_token) {
            const res = await refresh(token.refresh_token);
            if (res === null) {
                return config;
            }
            const newToken = await getAuthenticationInfo();
            if (newToken !== null) {
                token = newToken;
            }
        }

        config.headers.Authorization = 'Bearer ' + token.access_token;

        return config;
    });

    const requestInterceptors = settings().requestInterceptors;

    if (Array.isArray(requestInterceptors)) {
        requestInterceptors.forEach((interceptor) => {
            net.interceptors.request.use(interceptor);
        });
    }

    net.interceptors.response.use(async (response): Promise<AxiosResponse> => {
        if (response.status === 401) {
            if (response.config.url !== undefined && !response.config.url.includes(settings().oauth_token_url)) {
                // console.log('restore', response);
                // try to refresh if available and then retry the connection
                const token = await getAuthenticationInfo();
                if (token === null) {
                    throw {code: 401, config: response.config, response};
                } else {
                    if (response?.config?.data?.includes('refresh_token')) {
                        await setAuthenticationInfo(null)
                    } else {
                        if (await refresh(token.refresh_token)) {
                            return net(response.config);
                        }
                    }
                }
            } else {
                throw {code: 401, config: response.config, response};
            }
        }
        return response;
    });
}

/**
 * Request Wrapper with default success/error actions
 */
const request = (options: AxiosRequestConfig, fatalError: boolean | ((code: number) => boolean) = false, toasterError: boolean = false, retry?: () => void) => {
    const onSuccess = (response: AxiosResponse) => {

        // FIXME: disabled for performance issues
        // console.debug('Request Successful (' + options.url + '):', response.data);
        if (bugsnag) {
            bugsnag.leaveBreadcrumb('Request succeeded', {
                url: options.url,
                // FIXME: disabled for performance issues.
                // response: JSON.stringify(response.data),
            }, 'request');
        }
        // console.debug(JSON.stringify(response.data))
        return response.data;
    };

    const onError = (error: Error | AxiosError) => {
        if (axios.isAxiosError(error)) {
            if (error.config.data) {
                error.config.data = error.config.data.replace(/(password":")(.*?)(?=")/g, 'password":"******"')
                console.debug('Request Failed (' + error.config.url + '):', error.config);
            } else {
                console.debug('Request Failed (' + error.code + '):', error);
            }
            let status = 0;
            if (error.response) {
                if (bugsnag && error.config) {
                    bugsnag.leaveBreadcrumb('Request FAILED', {
                        requestUrl: error.config.url,
                        requestMethod: error.config.method,
                        requestHeaders: JSON.stringify(error.config.headers),
                        requestData: JSON.stringify(error.config.data),
                        responseStatus: error.response.status,
                        responseData: JSON.stringify(error.response.data),
                        responseHeaders: JSON.stringify(error.response.headers),
                    }, 'request');
                }
                status = error.response.status;
                // Request was made but server responded with something
                // other than 2xx
                console.debug('Status:', error.response.status);
                console.debug('Data:', error.response.data);
                console.debug('Headers:', error.response.headers);

            } else {
                if (bugsnag && error.config) {
                    bugsnag.leaveBreadcrumb('Request FAILED', {
                        message: error.message,
                        requestUrl: error.config.url,
                        requestMethod: error.config.method,
                        requestHeaders: JSON.stringify(error.config.headers),
                        requestData: JSON.stringify(error.config.data),
                    }, 'request');
                }
                // Something else happened while setting up the request
                // triggered the error
                console.debug('Error Message:', error.message);
            }

            if (fatalError === true || (fatalError !== false && fatalError(status))) {
                errorHandler((error.response && error.response.status + '') || error.code || '001', true, false, retry, error);
            } else if (toasterError) {
                errorHandler((error.response && 'Network error: ' + error.response.status + '') || error.message, false, true, retry, error);
            } else {
                errorHandler('Error', false, false, retry, error);
            }

            return Promise.reject(error);
        }

        errorHandler('Error', false, false, retry, error);

        return Promise.reject(error);
    };

    return net(options)
        .then(onSuccess)
        .catch(onError);
};

const get = (url: string, version = 'v1') => {
    return request({
        url,
        method: 'get',
        baseURL: settings().endpoint.replace('api/v1', 'api/' + version),
    });
};

const getOrFail = (url: string, onStatus?: (status: number) => boolean, version = 'v1') => {
    return request({
        url,
        method: 'get',
        baseURL: settings().endpoint.replace('api/v1', 'api/' + version),
    }, onStatus === undefined ? true : onStatus);
};

const getOrWarn = (url: string, retry?: () => void, version: string = 'v1', params: any = undefined) => {
    const config: AxiosRequestConfig = {
        url,
        method: 'get',
        baseURL: settings().endpoint.replace('api/v1', 'api/' + version),
    };

    if (params) {
        config.params = params;
    }

    return request(config, false, true, retry);
};

const post = (url: string, data?: any, version = 'v1', headers?: any) => {
    return request({
        url,
        method: 'post',
        data,
        baseURL: settings().endpoint.replace('api/v1', 'api/' + version),
        headers,
    });
};
const options = (url: string, data?: any, version = 'v1') => {
    return request({
        url,
        method: 'options',
        data,
        baseURL: settings().endpoint.replace('api/v1', 'api/' + version),
    });
};

const deleteCall = (url: string, data?: any, version = 'v1') => {
    return request({
        url,
        data,
        method: 'delete',
        baseURL: settings().endpoint.replace('api/v1', 'api/' + version),
    });
};

const patch = (url: string, data: any, version = 'v1', headers?: any) => {
    return request({
        url,
        method: 'patch',
        data,
        baseURL: settings().endpoint.replace('api/v1', 'api/' + version),
        headers,
    });
};
const put = (url: string, data: any, version = 'v1') => {
    return request({
        url,
        method: 'put',
        data,
        baseURL: settings().endpoint.replace('api/v1', 'api/' + version),
    });
};

const setAcceptLanguage = (language: string) => {
    if (net && net.defaults) {
        net.defaults.headers['Accept-Language'] = language;
    }
};

// Deprecated, use deviceId instead

const setAppId = (appId: string | null) => {
    if (appId) {
        appId = appId.toLowerCase();
    }
    net.defaults.headers['X-App-Id'] = appId;
};

const setDeviceId = (deviceId: string | null) => {
    bugsnag?.leaveBreadcrumb('Setting device id', {
        deviceId,
    }, 'log');
    net.defaults.headers['X-Device-Id'] = deviceId;
};

const setAppVersion = (appVersion: string) => {
    net.defaults.headers['X-App-Version'] = appVersion;
};
const setPlatform = () => {
    net.defaults.headers['X-Platform'] = 'web';
};

let refreshRequest: null|Promise<any> = null;

async function refresh(refresh_token: string): Promise<boolean> {
    const issued_on = new Date();
    let tokenRefreshed = false;

    if (!refreshRequest) {
        refreshRequest = post(settings().oauth_token_url, {
            refresh_token,
            grant_type: 'refresh_token',
            client_id: settings().client_id,
            client_secret: settings().client_secret,
        })
        .then((res) => {
            setAuthenticationInfo({...res, issued_on})
            tokenRefreshed = true;
        })
        .catch (e => setAuthenticationInfo(null))
        .finally(() => refreshRequest = null)
    }

    return tokenRefreshed;
}

const hasExpired = (token: AuthenticationInfo) => {
    return moment(token.issued_on).add(token.expires_in, 'seconds').isBefore();
};

const out = {
    init,
    get,
    getOrFail,
    getOrWarn,
    post,
    delete: deleteCall,
    options,
    patch,
    setAcceptLanguage,
    put,
    setAppId,
    setDeviceId,
    setPlatform,
    setAppVersion,
    getSettings,
};

export default out;
